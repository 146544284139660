<template>
<div class="full-width menu-header">
  <div class="container">
    <ul class="menulist">
      <li v-for="(item, k) in menus" v-bind:key="k" :class="hover + ' ' + item.label" @mouseover="resetHover()">
        <router-link :to="item.path" :class="item.path == currentRoute ? 'active' : ''" v-if="!/^https?:\/\//.test(item.path)">
          <div class="filter-inner">
            {{item.label}}
          </div>
        </router-link>
        <a :href="item.path" target="_blank" v-if="/^https?:\/\//.test(item.path)">{{item.label}}</a>
        <div class="expanded" v-if="item.secondLevel || item.fourthLevel">
          <div class="container flex-battle">
            <div class="pezzone" v-for="item in item.secondLevel" v-bind:key="item.path">
              <router-link :to="item.path" class="boldore"><span class="filter-inner">{{item.label}}</span></router-link>
              <router-link :to="item.path" v-for="(item, k) in item.thirdLevel" v-bind:key="k"><span class="filter-inner">{{item.label}}</span></router-link>
            </div>
              <div v-for="fourth in item.fourthLevel" v-bind:key="`f${fourth.id}`" class="fourth-level">
                <div class="pezzone" v-if="fourth.enabled">
                  <div class="boldore">{{fourth.nome}}</div>
                  <div class="multicolonna">
                    <div v-for="fourth2 in fourth.figli" v-bind:key="fourth2.id">
                      <router-link :to="item.path+'#f'+fourth2.id" @click="(fourth2.nome)" v-if="fourth2.enabledmenu">
                        <span class="filter-inner">{{fourth2.nome}}</span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </li>
      <li>|</li>
      <li><router-link to="/blog">BLOG</router-link></li>
    </ul>
  </div>
</div>
</template>
<script>
import utils from '@/utils.js';
export default {
  name: 'Menu',
  data () {
    const getSubmenuPath = item => '/category/' + item.categoryCode + '/' + utils.getSlug(utils.getCorrectName(item.categoryName));
    const getSubmenuName = item => item.categoryName ? item.categoryName.substr(item.categoryName.indexOf(" ") + 1) : '';
    const getSubmenu = code => utils.getSecondLevel(code).map(item => (
      item.path = getSubmenuPath(item),
      item.label = getSubmenuName(item),
      item.thirdLevel = utils.getSecondLevel(item.categoryCode).map(item => (
        item.path = getSubmenuPath(item),
        item.label = getSubmenuName(item),
        item
      )),
      item
    ));
    return {
      menus: [
        {label: 'PROMO', path: '/promo'},
        {label: 'ALIMENTARI', path: '/category/EC61000/alimentari', secondLevel: getSubmenu('EC61000'), fourthLevel: utils.getMenuFourthLevel('EC61000')},
        {label: 'COSMESI', path: '/category/EC62000/cosmesi', secondLevel: getSubmenu('EC62000'), fourthLevel: utils.getMenuFourthLevel('EC62000')},
        {label: 'BOMBONIERE', path: '/category/EC64000/bomboniere', secondLevel: getSubmenu('EC64000'), fourthLevel: utils.getMenuFourthLevel('EC64000')},
        {label: 'CASA', path: '/category/EC63000/casa', secondLevel: getSubmenu('EC63000'), fourthLevel: utils.getMenuFourthLevel('EC63000')},
        //{label: 'MODA', path: '/category/EC65000/moda'},
        {label: 'GIFT CARD', path: '/product/00004388/gift-card-20'},
        {label: 'IDEE REGALO', path: '/category/EC67000/idee-regalo'},
        {label: 'OUTLET', path: '/category/EC66000/outlet', secondLevel: getSubmenu('EC66000')},
        //{label: 'NATALE', path: '/natale'},
        {label: 'PASQUA', path: '/category/EC71800/pasqua'},
        // {label: 'CONCORSO', path: '/concorso'},
        // {label: 'SALDI', path: '/category/EF00007/saldi'}
        // {label: 'SALDI', path: '/category/EF00028/fiera-del-bianco'}
        //{label: 'FAQ', path: '/faq'}
      ],
      active: false,
      currentRoute: false,
      hover: "hover-item"
    }
  },
  methods: {
    resetHover () {
      this.hover = "hover-item"
    },
    fixMenuWidth() {
      document.querySelectorAll('.fourth-level .multicolonna').forEach($multicolonna => {
        $multicolonna.style.columns = '';
        const $parent = $multicolonna.parentNode;
        const offsetTop = $multicolonna.getBoundingClientRect().y - $parent.getBoundingClientRect().y;
        let colonne = 1;
        while(colonne < 6 && $parent.clientHeight < $multicolonna.offsetHeight + offsetTop) {
          $multicolonna.style.columns = colonne++;
        }
      })
    }
  },
  watch: {
    '$route': function () {
      this.hover = ""
    }
  },
  mounted () {
    this.fixMenuWidth()
    window.addEventListener('resize', this.fixMenuWidth);
  },
  unmounted () {
    window.removeEventListener('resize', this.fixMenuWidth);
  }
}
</script>
<style scoped>
.pezzone .router-link-exact-active,
.router-link-exact-active {
  text-decoration: underline !important;
}
.expanded {
  position: absolute;
  top: 120px;
  width: 100%;
  background-color: #eee;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  max-height: 65vh;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease;
  z-index: 50;
}
ul.menulist > li {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.menulist > li.hover-item:hover .expanded {
  pointer-events: all;
  opacity: 1;
}
.flex-battle {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  align-items: left;
  align-content: flex-start;
}
.pezzone {
  margin: 0 10px 10px 10px;
}

.pezzone>div,
.pezzone>a {
  display: block;
  height: 25px;
  line-height: 25px;
  font-weight: 400;
  text-decoration: none !important;
}
.multicolonna.multicolonna {
  height: auto;
  line-height: auto;
}
.multicolonna a {
  font-weight: 400;
  display: block;
}
.pezzone div {
  color: #000;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 14px;
  cursor: default;
}
.pezzone .boldore {
  font-weight: 700;
  text-transform: uppercase;
}
.pezzone div.filter-inner {
  display: inline-block;
}
.BOMBONIERE .expanded .flex-battle,
.CASA .expanded .flex-battle {
  flex-direction: row;
}
.fourth-level {
  height: 100%;
  margin-right: 15px;
}
.fourth-level > .pezzone {
  max-height: 100%;
}
.flex-battle > .fourth-level {
    border-left: 1px solid black;
}
.flex-battle > .fourth-level ~ .fourth-level {
    border: none;
    padding-left: none;
}
</style>