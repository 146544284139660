<template>
<div class="full-width sup-header">

  <div :class="'menu-mobile-shadow ' + (menuOpen ? 'open' : '')" @click="closeMenu()"></div>
  <div :class="'menu-mobile only-mobi ' + (menuOpen ? 'open' : '')">
    <img src="@/assets/logo.png" class="logo" />
    <ul class="menulist">
      <!--<li @click="closeMenu()"><router-link to="/">Promo</router-link></li>-->
      <li @click="closeMenu()"><router-link to="/promo">PROMO</router-link></li>
      <li @click="closeMenu()"><router-link to="/category/EC61000/alimentari">ALIMENTARI</router-link></li>
      <li @click="closeMenu()"><router-link to="/category/EC62000/cosmesi">COSMESI</router-link></li>
      <li @click="closeMenu()"><router-link to="/category/EC64000/bomboniere">BOMBONIERE</router-link></li>
      <li @click="closeMenu()"><router-link to="/category/EC63000/casa">CASA</router-link></li>
      <!-- <li @click="closeMenu()"><router-link to="/category/EC65000/moda">MODA</router-link></li> -->
      <li @click="closeMenu()"><router-link to="/product/00004388/gift-card-20">GIFT CARD</router-link></li>
      <!--<li @click="closeMenu()"><router-link to="/category/EF00001/prodotti-per-l-estate">PRODOTTI PER L'ESTATE</router-link></li>-->
      <li @click="closeMenu()"><router-link to="/category/EC67000/idee-regalo">IDEE REGALO</router-link></li>
      <li @click="closeMenu()"><router-link to="/category/EC66000/outlet">OUTLET</router-link></li>
      <!-- <li @click="closeMenu()"><router-link to="/natale">NATALE</router-link></li> -->
      <li @click="closeMenu()"><router-link to="/category/EC71800/pasqua">PASQUA</router-link></li>
      <!-- <li @click="closeMenu()"><router-link to="/concorso">CONCORSO</router-link></li> -->
      <!-- <li @click="closeMenu()"><router-link to="/category/EF00007/saldi">SALDI</router-link></li> -->
      <!-- <li @click="closeMenu()"><router-link to="/category/EF00028/fiera-del-bianco">SALDI</router-link></li> -->
      <li @click="closeMenu()"><router-link to="/faq">FAQ</router-link></li>
      <li @click="closeMenu()"><router-link to="/blog">BLOG</router-link></li>
      <li><hr></li>
      <li @click="closeMenu()"><router-link to="/account">IL MIO ACCOUNT</router-link></li>
      <li @click="closeMenu()"><router-link to="/cart">CARRELLO</router-link></li>
    </ul>
  </div>

  <div class="container sup-inner">
    <div class="col">
      <a class="altromercato-it" :href="$config.contentUrl">altromercato.it</a>
    </div>
    <div class="col">
      <router-link to="/">
        <img src="@/assets/logo.png" class="logo" />
      </router-link>
    </div>
    <div class="col pright" style="position:relative;">
      <div class="search">
        <input type="text" name="search" v-model="q" @keyup="searchEnter" />
        <a class="fa fa-search" @click="search"></a>
      </div>

      <div class="head-icon" style="cursor: pointer;">
        <img class="cart-icon" src="@/assets/user.png" alt="" @mouseover="mouseOver">
      </div>
      <div class="dropdownUser" v-show="active" @mouseleave="mouseLeaveDrop" v-if="$store.state.user.loggedIn">
        <p style="margin: 0 0 10px 0;">Bentornato, <b>{{$store.state.user.data.name}}</b></p>
        <router-link to="/account"><img src="@/assets/arrow.png" style="transform:rotate(180deg);width:10px;margin-right:10px">I miei ordini</router-link>
        <a href="#" @click="logout"><img src="@/assets/arrow.png" style="transform:rotate(180deg);width:10px;margin-right:10px">Logout</a>
      </div>
      <div class="dropdownUser" style="height:auto;" v-show="active" @mouseleave="mouseLeaveDrop" v-else>
        <router-link to="/account">Accedi / Registrati</router-link>
      </div>
      <router-link to="/cart" class="head-icon">
        <img class="cart-icon" src="@/assets/cart.png" />
        <!--<span class="fa fa-shopping-cart"></span>-->
        <div class="circle-items" v-if="cartItems > 0">{{cartItems}}</div>
      </router-link>
      <a href="#" @click="openMenu" class="head-icon only-mobi-ib"><span class="fa fa-bars"></span></a>
    </div>
  </div>
  <div class="search mobileSearch">
    <input type="text" name="search" v-model="q" @keyup="searchEnter" />
    <a class="fa fa-search" @click="search"></a>
  </div>
</div>
</template>

<script>


export default {
  name: 'Header',

  data: function () {
    return {
      q: '',
      menuOpen: false,
      active : false,
      dropActive : true
    }
  },
  computed: {
    cartItems () {
      let cart = this.$store.state.cart
      return cart.length
    }
  },

  methods: {
    logout () {
      this.$store.commit('logout')
      location.reload()
    },
    search () {
      if (this.q.length >= 3 || /t[eèé]/.test(this.q.toLowerCase())) {
        this.$router.push('/search/' + this.q)
        this.q = ''
      }
    },
    mouseOver(){
      this.active = true
      this.dropActive = true
    },
    mouseLeaveDrop(){
      this.active = false
      this.dropActive = false
    },
    searchEnter (evt) {
      if (evt.code == "Enter" && (this.q.length >= 3 || /t[eèé]/.test(this.q.toLowerCase()))) {
        this.$router.push('/search/' + this.q)
        this.q = ''
        evt.target.blur()
      }
    },

    openMenu(evt) {
      evt.preventDefault()
      this.menuOpen = true
    },

    closeMenu () {
      this.menuOpen = false
    }
  }
}



</script>

<style>
.dropdownUser{
  width: 200px;
  height: 150px;
  position: absolute;
  padding: 20px;
  background: white;
  border: 1px solid #3333;
  right: 0;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: all 0.3s linear 0s;
}
.dropdownUser:hover{
  display: flex;
}
@media (max-width: 680px) {
  .pright {
    padding-right: 10px !important;
  }
}
</style>
