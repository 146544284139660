import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Home2 from '../views/Home2.vue'
// import Concorso from '../views/Concorso.vue'
import RegolamentoPromozioni from '../views/RegolamentoPromozioni.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/natale',
    name: 'Home2',
    component: Home2
  },
  // {
  //   path: '/concorso',
  //   name: 'Concorso',
  //   component: Concorso
  // },
  {
    path: '/regolamento-promozioni',
    name: 'Regolamento Promozioni',
    component: RegolamentoPromozioni
  },
  {
    path: '/blog',
    name: 'Blog',
    component : () => import('../views/Blog.vue')
  },
  {
    path: '/post/:id/:slug',
    name: 'Single',
    component : () => import('../views/Single.vue')
  },
  {
    path: '/recipe/:id/:slug',
    name: 'Recipe',
    component : () => import('../views/Recipe.vue')
  },
  {
    path: '/category/EC65000/:slug',
    name: 'Category',
    component: () => import('../views/PaginaModa.vue')
  },
  {
    path: '/category/:code/:slug',
    name: 'Category',
    component: () => import('../views/Category.vue')
  },
  {
    path: '/brand/:name',
    name: 'Brand',
    component: () => import('../views/Brand.vue')
  },
  {
    path: '/product/:code/:slug',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/shopbylook/:slug',
    name: 'ShopByLook',
    component: () => import('../views/ShopByLook.vue')
  },
  {
    path: '/giftcard',
    name: 'Giftcard',
    component: () => import('../views/Giftcard.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/thankyou',
    name: 'Thankyou',
    component: () => import('../views/Thankyou.vue')
  },
  {
    path: '/failed',
    name: 'Failed',
    component: () => import('../views/Failed.vue')
  },
  {
    path: '/search/:q',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/pages/:slug',
    name: 'Page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/account/',
    name: 'Account',
    component: () => import('../views/Account.vue')
  },
  {
    path: '/promo/',
    name: 'Promo',
    component: () => import('../views/Promo.vue')
  },
  {
    path: '/newsletter/',
    name: 'Newsletter',
    component: () => import('../views/Newsletter.vue')
  },
  {
    path: '/servizio-clienti/',
    name: 'ServizioClienti',
    component: () => import('../views/ServizioClienti.vue')
  },
  {
    path: '/password-dimenticata/',
    name: 'Recover',
    component: () => import('../views/Recover.vue')
  },
  {
    path: '/newsletter/grazie',
    name: 'Grazie',
    component: () => import('../views/Grazie.vue')
  },
  {
    path: '/reimposta-password/:activation',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  },
  {
    path: '/altra-qualita',
    name: 'AltraQualita',
    component: () => import('../views/AltraQualita.vue')
  },
  {
    path: '/meridiano',
    name: 'Meridiano361',
    component: () => import('../views/Meridiano.vue')
  },
  {
    path: '/la-venenta',
    name: 'LaVenenta',
    component: () => import('../views/LaVenenta.vue')
  },
  {
    path: '/equo-mercato',
    name: 'EquoMercato',
    component: () => import('../views/EquoMercato.vue')
  },
  {
    path: '/zuccheri/:slug',
    name: 'Zuccheri',
    component: () => import('../views/PaginaZuccheri.vue')
  },
  {
    path: "*",
    name: 'Page404',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let popped = false
let scrollLevels = {}

window.addEventListener('popstate', function() {
  popped = true
})

router.beforeEach((to, from, next) => {
  if(popped) {
    popped = false
    setTimeout(() => window.scrollTo(0, scrollLevels[to.path]), 200)
  } else {
    scrollLevels[from.path] = window.scrollY
    window.scrollTo(0, 0)
  }
  next()
})

export default router
